import React, { Component } from "react"
import { withAuthenticator } from "aws-amplify-react"
import Amplify from "aws-amplify"
import { config } from "../config"

import Image from "../components/image"

import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import arrayMove from 'array-move'

import CookieService from "../services/CookieService"
import AlbumService from "../services/AlbumService"

import UserService from "../services/UserService"

import Layout from "../components/layout"
import SEO from "../components/seo"

import loadingIcon from "../images/loader.svg"
import saveIcon from "../images/menu/save.png"
import resetIcon from "../images/menu/restart.png"

// import inProgressIcon from '../images/in-progress.png'

Amplify.configure(config)

class AlbumSortPage extends Component {
  constructor(props) {
    super(props)

    this._isMounted = false
    this.selectedAlbum = null
    this.state = {
      loading: true,
      albumName: "",
      albumDescription: "",
      itemList: [],
      isSaved: false
    }
  }

  async componentDidMount() {
    this._isMounted = true
    const cookies = await CookieService.set() // eslint-disable-line no-unused-vars
    this.userInfo = await UserService.getUserInfo()
    this.selectedAlbum = this.props["*"]
    this.albums = await AlbumService.getAlbums()
    this.albumInfo = this.albums.find(album => album.albumId === this.selectedAlbum)
    this.albumItems = await AlbumService.getSortedAlbumItems(this.selectedAlbum)

    if (this._isMounted) {
      this.setState({
        loading: false,
        albumName: this.albumInfo.name,
        albumDescription: this.albumInfo.description,
        itemList: this.albumItems,
        isSaved: true
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({itemList}) => ({
      itemList: arrayMove(itemList, oldIndex, newIndex),
      isSaved: false
    }));
  };

  async onSave () {
    // console.log('onSave', this.state.itemList)
    await AlbumService.upsertAlbumSortData(this.selectedAlbum, this.state.itemList.map(item => item.key))
    this.setState({ isSaved: true })
    return
  }

  async onReset () {
    this.setState({
      itemList: await AlbumService.getSortedAlbumItems(this.selectedAlbum, true),
      isSaved: false
    })
  }
  
  render() {
    let content = ''
    if (this.props.loading) {
      content = <div className="loader" style={{ margin: '10rem' }}>
          <img src={loadingIcon} alt="loading" />
        </div>
    } else {
      const SortableItem = SortableElement(({ value }) => {
        return <div className='album-sort-item'>
          <div className='album-sort-item-inner'>
            <Image data={{ ...value, mode: 'ALBUM_SORT' }} aspectRatio={66.67} />
          </div>
        </div>}
      )
      const SortableList = SortableContainer(({items}) => {
        return (
          <ul className='album-sort-wrapper'>
            {items.map((item, index) => (
              <SortableItem key={`item-${index}`} index={index} value={item} />
            ))}
          </ul>
        );
      });
      content = <SortableList items={this.state.itemList} onSortEnd={this.onSortEnd} axis='xy' useWindowAsScrollContainer={true} />
    }
   

    return (
      <Layout menu="ALBUM">
        <SEO title="Album Sort" />
        <div className="album-header">
          <h1>{this.state.albumName}</h1>
          <div className="album-detail-block no-select">
            <div className="album-detail-block-inner">
              <h2>Outil de classification des médias</h2>
              <p>Glissez/déposez les médias dans l'ordre voulu puis cliquez sur le bouton de sauvegarde ci-dessous</p>
              <div className='btns-wrapper'>
                <button onClick={() => this.onSave()} title="Sauvegarder" disabled={this.state.isSaved}>
                  <img src={saveIcon} alt="Sauvegarder"></img>
                </button>
                <button onClick={() => this.onReset()} title="Redémarrer">
                  <img src={resetIcon} alt="Redémarrer"></img>
                </button>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '.75rem' }}>
            {content}
          </div>
        </div>
      </Layout>
    )
  }
}

export default withAuthenticator(AlbumSortPage, {
  includeGreetings: false,
})
